<template>
  <footer class="bg-theme-primary w-full flex flex-col lg:flex-row p-2 lg:p-1">
    <div class="container flex flex-col lg:flex-row items-center gap-2 lg:p-1 max-w-6xl mx-auto">
      <NuxtLink to="/" class="flex items-center">
        <IconLogo class="h-[45px]" :dark="true"/>
        <div class="px-1">
          <h1 class="font-semibold text-[1.3125rem] visible">{{ platform }}</h1>
        </div>
      </NuxtLink>
      <div class="flex gap-2 grow">
        <div class="flex flex-col sm:flex-row gap-1 lg:gap-2 lg:justify-end grow">
          <a :href="'mailto:info@'+platform+'.ch'">info@{{ platform }}.ch</a>
          <a href="https://www.moveable.ch" target="_blank">moveable.ch</a>
          <NuxtLink to="/about" hash="#faq">FAQ</NuxtLink>
        </div>
        <div class="flex flex-col sm:flex-row gap-1 lg:gap-2 lg:justify-end">
          <NuxtLink to="/imprint">{{ $t('footer.imprint') }}</NuxtLink>
          <a :href="'https://www.instagram.com/moveable_ch/'" target="_blank">Instagram</a>
<!--          <NuxtLink to="/newsletter">{{ $t('footer.newsletter') }}</NuxtLink>-->
        </div>
      </div>
    </div>
  </footer>
  <div v-if="showPartner" class="bg-theme-bg-neg w-full flex flex-col items-center justify-center gap-1 p-2">
    <p>{{ $t('general.supported_by')}}</p>
    <div v-if="platform == 'bikeable'" class="flex items-center justify-center gap-2">
    <img src="/partner/veloplus.png" alt="Velo Plus" class="w-[150px]"/>
    <img src="/partner/provelo.png" alt="Pro Velo" class="w-[150px]"/>
    </div>
    <div v-else class="flex items-center justify-center gap-2">
      <img w="150" src="/partner/fussverkehr.png" :alt="$t('partners.fussverkehr_schweiz')" class="w-[150px]"/>
    </div>
  </div>
</template>
<script setup lang="ts">
const platform = usePlatformSettings().platformName;
const countryCode = usePlatformSettings().countryCode;

const showPartner = ref(countryCode.value === 'ch');
</script>
